import React from "react";
import { useWindowWidth } from "../../breakpoints";
import "./style.css";

export const RileyWebsite = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="riley-website"
      style={{
        minWidth:
          screenWidth < 768
            ? "375px"
            : screenWidth >= 768 && screenWidth < 1024
            ? "768px"
            : screenWidth >= 1024 && screenWidth < 1280
            ? "1024px"
            : screenWidth >= 1280
            ? "1280px"
            : undefined,
      }}
    >
      <div
        className="frame"
        style={{
          gap:
            screenWidth < 768
              ? "55px"
              : screenWidth >= 768 && screenWidth < 1024
              ? "268px"
              : screenWidth >= 1024 && screenWidth < 1280
              ? "376px"
              : screenWidth >= 1280
              ? "633px"
              : undefined,
          height:
            screenWidth < 768
              ? "66px"
              : (screenWidth >= 1024 && screenWidth < 1280) ||
                screenWidth >= 1280 ||
                (screenWidth >= 768 && screenWidth < 1024)
              ? "114px"
              : undefined,
        }}
      >
        <div
          className="otium-icon"
          style={{
            gap:
              screenWidth < 768
                ? "3.53px"
                : (screenWidth >= 1024 && screenWidth < 1280) ||
                  screenWidth >= 1280 ||
                  (screenWidth >= 768 && screenWidth < 1024)
                ? "5px"
                : undefined,
            width:
              screenWidth < 768
                ? "101.89px"
                : (screenWidth >= 1024 && screenWidth < 1280) ||
                  screenWidth >= 1280 ||
                  (screenWidth >= 768 && screenWidth < 1024)
                ? "144.18px"
                : undefined,
          }}
        >
          <img
            className="group"
            style={{
              height:
                screenWidth < 768
                  ? "21.93px"
                  : (screenWidth >= 1024 && screenWidth < 1280) ||
                    screenWidth >= 1280 ||
                    (screenWidth >= 768 && screenWidth < 1024)
                  ? "31.02px"
                  : undefined,
              width:
                screenWidth < 768
                  ? "21.32px"
                  : (screenWidth >= 1024 && screenWidth < 1280) ||
                    screenWidth >= 1280 ||
                    (screenWidth >= 768 && screenWidth < 1024)
                  ? "30.18px"
                  : undefined,
            }}
            alt="Group"
            src={
              screenWidth < 768
                ? "/img/group-247-1.png"
                : (screenWidth >= 1024 && screenWidth < 1280) || (screenWidth >= 768 && screenWidth < 1024)
                ? "/img/group-247-2.png"
                : screenWidth >= 1280
                ? "/img/group-247.png"
                : undefined
            }
          />
          <div
            className="text-wrapper"
            style={{
              fontSize:
                screenWidth < 768
                  ? "26.2px"
                  : (screenWidth >= 1024 && screenWidth < 1280) ||
                    screenWidth >= 1280 ||
                    (screenWidth >= 768 && screenWidth < 1024)
                  ? "37.1px"
                  : undefined,
              marginTop:
                screenWidth < 768
                  ? "-0.37px"
                  : (screenWidth >= 1024 && screenWidth < 1280) ||
                    screenWidth >= 1280 ||
                    (screenWidth >= 768 && screenWidth < 1024)
                  ? "-0.53px"
                  : undefined,
            }}
          >
            otium
          </div>
        </div>
        <div
          className="div"
          style={{
            gap:
              screenWidth < 768
                ? "28.27px"
                : (screenWidth >= 1024 && screenWidth < 1280) ||
                  screenWidth >= 1280 ||
                  (screenWidth >= 768 && screenWidth < 1024)
                ? "40px"
                : undefined,
            padding:
              screenWidth < 768
                ? "0px 0px 0px 19.79px"
                : (screenWidth >= 1024 && screenWidth < 1280) ||
                  screenWidth >= 1280 ||
                  (screenWidth >= 768 && screenWidth < 1024)
                ? "0px 0px 0px 28px"
                : undefined,
            width:
              screenWidth < 768
                ? "149.11px"
                : (screenWidth >= 1024 && screenWidth < 1280) ||
                  screenWidth >= 1280 ||
                  (screenWidth >= 768 && screenWidth < 1024)
                ? "211px"
                : undefined,
          }}
        >
          <div
            className="view"
            style={{
              gap:
                screenWidth < 768
                  ? "5.65px"
                  : (screenWidth >= 1024 && screenWidth < 1280) ||
                    screenWidth >= 1280 ||
                    (screenWidth >= 768 && screenWidth < 1024)
                  ? "8px"
                  : undefined,
            }}
          >
            <div
              className="text-wrapper-2"
              style={{
                fontSize:
                  screenWidth < 768
                    ? "14.1px"
                    : (screenWidth >= 1024 && screenWidth < 1280) ||
                      screenWidth >= 1280 ||
                      (screenWidth >= 768 && screenWidth < 1024)
                    ? "20px"
                    : undefined,
                marginTop:
                  screenWidth < 768
                    ? "-0.71px"
                    : (screenWidth >= 1024 && screenWidth < 1280) ||
                      screenWidth >= 1280 ||
                      (screenWidth >= 768 && screenWidth < 1024)
                    ? "-1.00px"
                    : undefined,
              }}
            >
              서비스 소개
            </div>
          </div>
          <div
            className="FAQ"
            style={{
              gap:
                screenWidth < 768
                  ? "5.65px"
                  : (screenWidth >= 1024 && screenWidth < 1280) ||
                    screenWidth >= 1280 ||
                    (screenWidth >= 768 && screenWidth < 1024)
                  ? "8px"
                  : undefined,
            }}
          >
            <div
              className="FAQ-2"
              style={{
                fontSize:
                  screenWidth < 768
                    ? "14.1px"
                    : (screenWidth >= 1024 && screenWidth < 1280) ||
                      screenWidth >= 1280 ||
                      (screenWidth >= 768 && screenWidth < 1024)
                    ? "20px"
                    : undefined,
                marginTop:
                  screenWidth < 768
                    ? "-0.71px"
                    : (screenWidth >= 1024 && screenWidth < 1280) ||
                      screenWidth >= 1280 ||
                      (screenWidth >= 768 && screenWidth < 1024)
                    ? "-1.00px"
                    : undefined,
              }}
            >
              {" "}
              FAQ
            </div>
          </div>
        </div>
      </div>
      <div
        className="frame-2"
        style={{
          flexDirection: screenWidth < 768 ? "column" : undefined,
          gap:
            (screenWidth >= 1024 && screenWidth < 1280) || (screenWidth >= 768 && screenWidth < 1024)
              ? "20px"
              : screenWidth >= 1280
              ? "14px"
              : undefined,
          height:
            screenWidth < 768
              ? "729px"
              : screenWidth >= 768 && screenWidth < 1024
              ? "664px"
              : (screenWidth >= 1024 && screenWidth < 1280) || screenWidth >= 1280
              ? "668px"
              : undefined,
          justifyContent:
            (screenWidth >= 1024 && screenWidth < 1280) ||
            screenWidth >= 1280 ||
            (screenWidth >= 768 && screenWidth < 1024)
              ? "center"
              : undefined,
        }}
      >
        <div
          className="frame-3"
          style={{
            display:
              (screenWidth >= 1024 && screenWidth < 1280) ||
              (screenWidth >= 768 && screenWidth < 1024) ||
              screenWidth < 768
                ? "inline-flex"
                : screenWidth >= 1280
                ? "flex"
                : undefined,
            flex:
              (screenWidth >= 1024 && screenWidth < 1280) ||
              (screenWidth >= 768 && screenWidth < 1024) ||
              screenWidth < 768
                ? "0 0 auto"
                : undefined,
            gap:
              screenWidth < 768
                ? "9.38px"
                : screenWidth >= 768 && screenWidth < 1024
                ? "9.45px"
                : screenWidth >= 1024 && screenWidth < 1280
                ? "12.45px"
                : screenWidth >= 1280
                ? "18.09px"
                : undefined,
            justifyContent: (screenWidth >= 1024 && screenWidth < 1280) || screenWidth >= 1280 ? "center" : undefined,
            padding:
              screenWidth < 768 ? "20px 9.38px 0px" : screenWidth >= 768 && screenWidth < 1024 ? "9.45px" : undefined,
            width: screenWidth >= 1280 ? "443.21px" : undefined,
          }}
        >
          <div
            className="frame-4"
            style={{
              gap:
                screenWidth >= 1024 && screenWidth < 1280
                  ? "15.95px"
                  : screenWidth >= 1280
                  ? "23.19px"
                  : screenWidth < 768
                  ? "11.76px"
                  : screenWidth >= 768 && screenWidth < 1024
                  ? "11.86px"
                  : undefined,
            }}
          >
            {((screenWidth >= 1024 && screenWidth < 1280) || screenWidth >= 1280) && (
              <div
                className="div-wrapper"
                style={{
                  gap:
                    screenWidth >= 1024 && screenWidth < 1280 ? "12.27px" : screenWidth >= 1280 ? "17.84px" : undefined,
                  width:
                    screenWidth >= 1024 && screenWidth < 1280
                      ? "304.95px"
                      : screenWidth >= 1280
                      ? "443.21px"
                      : undefined,
                }}
              >
                <p
                  className="p"
                  style={{
                    fontSize:
                      screenWidth >= 1024 && screenWidth < 1280 ? "29.5px" : screenWidth >= 1280 ? "42.8px" : undefined,
                    marginTop:
                      screenWidth >= 1024 && screenWidth < 1280
                        ? "-0.61px"
                        : screenWidth >= 1280
                        ? "-0.89px"
                        : undefined,
                  }}
                >
                  <span className="span">
                    하루하루 마음 챙김...
                    <br />
                    감정을 기록하는
                    <br />
                    가장 쉬운 방법,{" "}
                  </span>
                  <span className="text-wrapper-3">라일리</span>
                </p>
              </div>
            )}

            {((screenWidth >= 768 && screenWidth < 1024) || screenWidth < 768) && (
              <>
                <div
                  className="frame-wrapper"
                  style={{
                    gap:
                      screenWidth < 768 ? "15.07px" : screenWidth >= 768 && screenWidth < 1024 ? "15.2px" : undefined,
                  }}
                >
                  <div
                    className="frame-5"
                    style={{
                      gap:
                        screenWidth < 768 ? "11.6px" : screenWidth >= 768 && screenWidth < 1024 ? "11.69px" : undefined,
                      width:
                        screenWidth < 768
                          ? "288.15px"
                          : screenWidth >= 768 && screenWidth < 1024
                          ? "290.56px"
                          : undefined,
                    }}
                  >
                    <p
                      className="div-2"
                      style={{
                        fontSize:
                          screenWidth < 768
                            ? "27.8px"
                            : screenWidth >= 768 && screenWidth < 1024
                            ? "28.1px"
                            : undefined,
                      }}
                    >
                      <span className="span">
                        하루하루 마음 챙김...
                        <br />
                        감정을 기록하는
                        <br />
                        가장 쉬운 방법,{" "}
                      </span>
                      <span className="text-wrapper-3">라일리</span>
                    </p>
                  </div>
                </div>
                <div
                  className="frame-6"
                  style={{
                    borderRadius:
                      screenWidth < 768 ? "4.64px" : screenWidth >= 768 && screenWidth < 1024 ? "4.68px" : undefined,
                    gap: screenWidth < 768 ? "5.8px" : screenWidth >= 768 && screenWidth < 1024 ? "5.85px" : undefined,
                    padding:
                      screenWidth < 768 ? "9.28px" : screenWidth >= 768 && screenWidth < 1024 ? "9.35px" : undefined,
                  }}
                >
                  <div
                    className="frame-7"
                    style={{
                      gap:
                        screenWidth < 768 ? "9.28px" : screenWidth >= 768 && screenWidth < 1024 ? "9.35px" : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-4"
                      style={{
                        fontSize:
                          screenWidth < 768
                            ? "16.2px"
                            : screenWidth >= 768 && screenWidth < 1024
                            ? "16.4px"
                            : undefined,
                        lineHeight:
                          screenWidth < 768
                            ? "18.2px"
                            : screenWidth >= 768 && screenWidth < 1024
                            ? "18.3px"
                            : undefined,
                        marginTop:
                          screenWidth < 768
                            ? "-1.16px"
                            : screenWidth >= 768 && screenWidth < 1024
                            ? "-1.17px"
                            : undefined,
                      }}
                    >
                      Coming Soon
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {((screenWidth >= 1024 && screenWidth < 1280) || screenWidth >= 1280) && (
            <div
              className="frame-8"
              style={{
                borderRadius:
                  screenWidth >= 1024 && screenWidth < 1280 ? "4.91px" : screenWidth >= 1280 ? "7.13px" : undefined,
                gap: screenWidth >= 1024 && screenWidth < 1280 ? "6.14px" : screenWidth >= 1280 ? "8.92px" : undefined,
                padding:
                  screenWidth >= 1024 && screenWidth < 1280 ? "9.82px" : screenWidth >= 1280 ? "14.27px" : undefined,
              }}
            >
              <div
                className="frame-9"
                style={{
                  gap:
                    screenWidth >= 1024 && screenWidth < 1280 ? "9.82px" : screenWidth >= 1280 ? "14.27px" : undefined,
                }}
              >
                <div
                  className="text-wrapper-5"
                  style={{
                    fontSize:
                      screenWidth >= 1024 && screenWidth < 1280 ? "17.2px" : screenWidth >= 1280 ? "25px" : undefined,
                    lineHeight:
                      screenWidth >= 1024 && screenWidth < 1280 ? "19.2px" : screenWidth >= 1280 ? "28.0px" : undefined,
                    marginTop:
                      screenWidth >= 1024 && screenWidth < 1280
                        ? "-1.23px"
                        : screenWidth >= 1280
                        ? "-1.78px"
                        : undefined,
                  }}
                >
                  Coming Soon
                </div>
              </div>
            </div>
          )}
        </div>
        <img
          className="iphone-pro"
          style={{
            height:
              screenWidth < 768
                ? "534.69px"
                : screenWidth >= 768 && screenWidth < 1024
                ? "664px"
                : (screenWidth >= 1024 && screenWidth < 1280) || screenWidth >= 1280
                ? "668px"
                : undefined,
            width:
              screenWidth < 768
                ? "297px"
                : screenWidth >= 768 && screenWidth < 1024
                ? "342.68px"
                : screenWidth >= 1024 && screenWidth < 1280
                ? "425px"
                : screenWidth >= 1280
                ? "554.22px"
                : undefined,
          }}
          alt="Iphone pro"
          src={
            screenWidth < 768
              ? "/img/iphone-13-pro-1.png"
              : screenWidth >= 768 && screenWidth < 1024
              ? "/img/iphone-13-pro-2.png"
              : screenWidth >= 1024 && screenWidth < 1280
              ? "/img/iphone-13-pro-3.png"
              : screenWidth >= 1280
              ? "/img/iphone-13-pro.png"
              : undefined
          }
        />
      </div>
      <div
        className="frame-10"
        style={{
          padding:
            screenWidth < 768
              ? "40px 70px"
              : screenWidth >= 768 && screenWidth < 1024
              ? "30px 70px"
              : screenWidth >= 1024 && screenWidth < 1280
              ? "30px 144px"
              : screenWidth >= 1280
              ? "30px 143px"
              : undefined,
        }}
      >
        <div
          className="frame-11"
          style={{
            height: screenWidth >= 1280 ? "96px" : undefined,
            marginLeft: screenWidth < 768 ? "-35.00px" : undefined,
            marginRight: screenWidth < 768 ? "-35.00px" : undefined,
            width:
              screenWidth < 768
                ? "305px"
                : screenWidth >= 768 && screenWidth < 1024
                ? "627px"
                : screenWidth >= 1024 && screenWidth < 1280
                ? "736px"
                : screenWidth >= 1280
                ? "994px"
                : undefined,
          }}
        >
          <div className="frame-12">
            <div className="text-wrapper-6">오티움</div>
          </div>
          <div className="element-email-wrapper">
            <div
              className="element-email"
              style={{
                fontSize:
                  screenWidth < 768
                    ? "12px"
                    : (screenWidth >= 1024 && screenWidth < 1280) ||
                      screenWidth >= 1280 ||
                      (screenWidth >= 768 && screenWidth < 1024)
                    ? "14px"
                    : undefined,
              }}
            >
              {screenWidth < 768 && (
                <p className="text-wrapper-7">
                  사업자 등록번호: 649-81-03460 | 대표자명: 임태영
                  <br />
                  Email: admin@otium.day <br />
                  서울특별시 강동구 상일로 55, 111동 1006호
                </p>
              )}

              {((screenWidth >= 1024 && screenWidth < 1280) ||
                screenWidth >= 1280 ||
                (screenWidth >= 768 && screenWidth < 1024)) && (
                <p className="text-wrapper-7">
                  사업자 등록번호: 649-81-03460 | 대표자명: 임태영
                  <br />
                  Email: admin@otium.day | 서울특별시 강동구 상일로 55, 111동 1006호
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
